import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { PickDealerMenu } from '~/modules/investment-consultant/orderBox/MutipleDealerQuickFuturesOrderBox/Component/PickDealerMenu';
import { memo, useEffect, useState } from 'react';
import { dealerList, } from '~/modules/investment-consultant/orderBox/MutipleDealerQuickFuturesOrderBox/Function/dealerInfoList';
/** google標單填寫預約開戶服務 */
//https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform
const openAccounURL = {
    ibf: {},
    capital: {},
};
// 使用映射对象来简化 URL 设置逻辑
const getUrlForDealer = (dealer, agentName) => {
    const urlMapping = {
        ibf: () => dealerList.find(d => d.name === 'ibf')?.openAccountUrl[agentName] || '',
        capital: () => dealerList.find(d => d.name === 'capital')?.openAccountUrl[agentName] || '',
        // 可以根据需要添加更多券商的逻辑
    };
    // 执行映射的函数，如果没有找到对应的处理函数，则使用通用 URL
    return (urlMapping[dealer.name] || (() => ''))();
};
export const MutipleFormOpenAccount = memo(function MutipleFormOpenAccount(props) {
    const [selectedDealer, setSelectedDealer] = useState(dealerList[0]);
    const [url, setUrl] = useState(dealerList[0].openAccountUrl[props.AgentName] || '');
    function handleClick(dealer) {
        setSelectedDealer(dealer);
    }
    // 預設券商網址
    useEffect(() => {
        // 根據選擇的券商設置 URL
        const newUrl = getUrlForDealer(selectedDealer, props.AgentName);
        setUrl(newUrl);
        if (newUrl === '') {
            console.error('URL is empty');
        }
    }, [selectedDealer, props.AgentName]);
    return (<classes.container>
        {/* <styleds.mutipleDealerSelect> */}
        <classes.title>便利下單功能申請</classes.title>

        {/* 券商選項 */}
        <PickDealerMenu handleClick={handleClick} selectedDealer={selectedDealer}/>
        {/* </styleds.mutipleDealerSelect> */}
        <a href={url} target='_blank' rel='noreferrer' data-hover='GO' css={css `
            display: inline-block; // 使連結像一個塊元素一樣表現，這樣就可以應用padding和其他布局屬性
            display: flex; /* 使用 flexbox */
            justify-content: center; /* 水平居中 */
            align-items: center; /* 垂直居中 */
            position: relative;
            padding: 2px;
            gap: 4px;
            border-radius: 5px;
            cursor: pointer;
            background-color: rgb(76, 86, 95);
            /* line-height: 36px; */
            transition: 0.3s;
            border: 1px solid #cccccc;
            text-align: center; // 確保文本在按鈕內居中
            text-decoration: none; // 移除下劃線
            color: #ffffff; // 設定文本顏色
            /* &:hover {
              background-color: #578aef;
            } */
            & span,
            &:before {
              font-size: 16px;
              font-weight: bold;
              text-transform: uppercase;
              transition: all 0.3s ease-in-out;

              /* padding: 10px 20px; */
            }
            &:before {
              content: attr(data-hover);
              position: absolute;
              top: 50%;
              left: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              transform: translate(-100%, 0);
              display: none;
            }
            &:hover span {
              opacity: 0;
              transform: translate(100%, 0);
            }
            &:hover:before {
              opacity: 1;
              transform: translate(0, 0);
              display: inline;
            }
          `}>
          <span>確認</span>
        </a>
      </classes.container>);
});
const classes = {
    container: styled.div `
    border-radius: 4px;
    /* ${fill_horizontal_cross_center}; */
    display: grid;
    grid-template-columns: 40% 40% 20%;
    height: 40px;
    width: 100%;
    line-height: 0;
    /* padding: 20px;
    height: 30px; */
    /* margin: 2px; */
    /* border-bottom: 1px solid #cccccc; */
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    font-size: 14px;
  `,
};
// const openAccounURL =
//   'https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform'
// export const FromOpenAccountMantineButton = memo<ReactProps>(
//   function FromOpenAccountMantineButton() {
//     return (
//       <Button
//         css={css`
//           width: 136px;
//           height: 40px;
//           font-size: 13px;
//           padding-right: 0;
//           padding-left: 0;
//         `}
//         color='gray'
//         variant='gradient'
//         gradient={{ from: 'red', to: 'yellow' }}
//         onClick={() => window.open(openAccounURL, '_blank')}
//       >
//         開戶服務預約表單
//       </Button>
//     )
//   },
// )
